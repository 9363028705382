export class createUserResourceModel {

    constructor(connection = 'Username-Password-Authentication', verifyEmail = true, email = '', userMetadata = null, nickname = null, firstName = '', fullName = null, lastName = '', picture = '', blocked = false) {
        this.connection = connection,
            this.verifyEmail = verifyEmail,
            this.email = email
            this.userMetadata = userMetadata,
            this.nickname = nickname,
            this.firstName = firstName,
            this.fullName = fullName,
            this.lastName = lastName,
            this.picture = picture,
            this.blocked = blocked
    }
}