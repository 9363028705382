<template>
  <section>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form action="/administration/users" @submit.prevent="onSubmit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                autofocus
                v-model="form.firstName"
                label="First Name"
                rules="required"
                required
              />
            </v-col>
            <v-col sm="6">
              <validated-text-field v-model="form.lastName" label="Last Name" rules="required"></validated-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6">
              <validated-text-field
                type="email"
                v-model="form.email"
                label="Email"
                rules="email|required"
              />
            </v-col>
            <v-col sm="6">
              <validated-text-field v-model="form.fullName" label="Full Name" />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col sm="6">
              <validated-text-field v-model="form.nickName" label="Nickname" />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col sm="6"></v-col>
            <v-col sm="6"></v-col>
          </v-row>
          <!-- <v-checkbox v-model="form.isAdministrator" label="Is Administrator" /> -->
        </v-sheet>
        <v-row>
          <v-col>
            <v-btn :disabled="invalid" type="submit" color="primary" class="mr-5">Save</v-btn>
            <v-btn :to="`/administration/users`">Cancel</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";
import { createUserResourceModel } from "../_resourceModels/createUserResourceModel";
import api from "../_api";
const generator = require("generate-password");

export default {
  components: {
    "validation-observer": ValidationObserver,
    "validated-text-field": ValidatedTextField
  },

  data() {
    return {
      valid: false,
      form: new createUserResourceModel(),
      proposedPassword: "",
      loading: false
    };
  },
  mounted() {},

  methods: {
    onCancel() {
      this.$emit("cancel");
    },

    async onSubmit() {
      this.loading = true;
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }
      this.form.password = this.generatePassword();
      this.form.picture =
        "https://gravatar.com/avatar/c6780dbd49b4151e49201ec2a5791f4b?s=400&d=identicon&r=r";

      try {
        this.loading = true;
        let success = await api.inviteUser(this.form);
        this.$toast.show(`${this.form.fullName} has been invited to Energy360.`, null, "success");
        // if (success) {
        this.$router.push({ name: "UserIndex" });
        console.log(success);
        // }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    handleSuccess() {
      this.setText("Successfully created the user.");
    },

    handleError() {
      this.setText("There was an error saving the user.");
    },

    generatePassword() {
      let password = generator.generate({
        length: 10,
        numbers: true,
        symbols: true,
        uppercase: true,
        lowercase: true,
        excludeSimilarCharacters: true
      });
      return password;
    },

    ...mapActions("snackbar", ["setText"])
  }
};
</script>